const SkeletonRow = () => (
    <div className="animate-pulse flex space-x-4">
        <div className="rounded bg-gray-300 h-4 w-3/4"></div>
        <div className="rounded bg-gray-300 h-4 w-1/4"></div>
    </div>
);

const SkeletonLoader = ({ rows = 5 }) => (
    <div className="delayed-flights-table my-10 text-left text-black bg-neutral-100 p-5 rounded w-[95%] max-w-screen-lg overflow-hidden m-auto">
        <div className="font-semibold mb-3 text-sm md:text-base bg-gray-300 w-1/2 h-6 rounded animate-pulse"></div>
        <div className="overflow-x-hidden">
            <div className="space-y-2">
                {[...Array(rows)].map((_, index) => (
                    <SkeletonRow key={index} />
                ))}
            </div>
        </div>
    </div>
);

export default SkeletonLoader;
