import React from 'react';
import { Fragment } from 'react';
import useTranslation from 'next-translate/useTranslation';
import SkeletonLoader from './SkeletonLoader';

const FlightTable = ({ flights }) => {
    const { t } = useTranslation('common');
    // if flights is a string, return nothing
    if (!flights || typeof flights === 'string') {
        return SkeletonLoader({ rows: 8 });
    }
    if (flights.length === 0)
        return (
            <div className="flex grow mb-10">
                <div className="delayed-flights-table mt-10 text-left text-black bg-neutral-100 p-5 rounded w-full max-w-screen-lg overflow-y-auto h-fit max-h-[50vh]">
                    <div className="font-semibold text-center">
                        {t('common:no_delays')}
                    </div>
                </div>
            </div>
        );
    return (
        <div className="delayed-flights-table my-10 text-left text-black bg-neutral-100 p-5 rounded w-[95%] max-w-screen-lg overflow-hidden m-auto">
            <div className="font-semibold mb-3 text-sm md:text-base">
                {t('common:delays_with_12_hours')}
            </div>

            <div className="overflow-x-hidden">
                <table className="w-full border-separate border-spacing-x-0 border-spacing-y-2">
                    <thead className="sr-only">
                        <tr>
                            <th scope="col">{t('common:departure_time')}</th>
                            <th scope="col">{t('common:destination')}</th>
                            <th scope="col" className="hidden md:block">
                                {t('common:airline')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {flights.map((flight, index) => {
                            const [place, airport] =
                                flight.DestinationName.split('-');
                            return (
                                <Fragment key={`flight-rows-${index}`}>
                                    <tr
                                        key={`desktop-${index}`}
                                        className="hidden md:table-row bg-white rounded my-2 drop-shadow cursor-pointer hover:bg-neutral-200 transition-all"
                                        onClick={() => {
                                            window.open(
                                                `https://www.cph.dk/flyinformation/afgange/${flight.Iata}?id=${flight.FlightId}`,
                                                '_blank'
                                            );
                                        }}
                                        tabIndex="0" // Enable keyboard focus
                                        role="button" // Indicate interactive element
                                        aria-label={`Flight details for ${place} ${airport}, departing at ${flight.Time} with ${flight.Airline}`}
                                    >
                                        <td className="p-4 px-8">
                                            <div className="font-light line-through whitespace-nowrap w-min">
                                                {flight.Time}
                                            </div>
                                            <div className="font-semibold whitespace-nowrap w-min">
                                                {flight.ExpectedTime}
                                            </div>
                                        </td>

                                        <td className="p-4 px-8">
                                            <div className="font-semibold">
                                                {place} {airport}
                                            </div>
                                            <div>{flight.Iata}</div>
                                        </td>
                                        <td className="p-4 px-8 font-semibold">
                                            <div>{flight.Airline}</div>
                                        </td>
                                        <td className="p-4 pl-0">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6 ml-auto"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M9 5l7 7-7 7"
                                                />
                                            </svg>
                                        </td>
                                    </tr>
                                    <tr
                                        key={`mobile-${index}`}
                                        className="text-sm md:hidden bg-white rounded my-2 drop-shadow cursor-pointer hover:bg-neutral-200 transition-all"
                                        onClick={() => {
                                            if (typeof umami !== 'undefined') {
                                                umami.track('flight', {
                                                    flight
                                                });
                                            }
                                            window.open(
                                                `https://www.cph.dk/flyinformation/afgange/${flight.Iata}?id=${flight.FlightId}`,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <td className="p-2 px-4">
                                            <div className="font-light line-through whitespace-nowrap w-min">
                                                {flight.Time}
                                            </div>
                                            <div className="font-semibold whitespace-nowrap w-min">
                                                {flight.ExpectedTime}
                                            </div>
                                        </td>

                                        <td className="py-2 mx-4 overflow-hidden max-w-[200px]">
                                            <div className="whitespace-nowrap w-min">
                                                <span className="font-semibold">
                                                    {flight.Destination + ' '}
                                                </span>
                                                ({flight.IataDestination})
                                            </div>
                                            <div className="whitespace-nowrap w-min">
                                                {flight.Iata}
                                            </div>
                                            <div className="whitespace-nowrap w-min">
                                                {flight.AirlineMobile}
                                            </div>
                                        </td>
                                        <td className="p-2 pl-0">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6 ml-auto"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M9 5l7 7-7 7"
                                                />
                                            </svg>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
                <pre className="hidden">
                    {JSON.stringify(flights[0], null, 2)}
                </pre>
            </div>
        </div>
    );
};

export default FlightTable;
